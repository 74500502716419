.pagination {
  clear: both;
  padding: 20px 0;
  text-align: right;

  li {
    display: inline-block;

    a {
      .font-size(12);

      margin: 0 5px;
      color: @color-link;
    }

    &.active {
      a {
        points-event: none;
        color: @color-dark;
      }
    }
  }
}
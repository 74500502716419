input, button, select {
  -webkit-appearance: none;
  border-radius: 0;
}

.locked {
  position: relative;
}

.form-line {
  .clearfix();

  .wide-input {
    width: 373px;
  }

  .narrow-input {
    width: 204px;
  }

  .half-input,
  .half-input-desktop-only {
    width: 49%;
  }


  @media @mobile {
    .wide-input {
      width: 100%;
    }

    .narrow-input {
      width: 100%;
    }

    .half-input-desktop-only {
      width: 100%;
    }
  }
}

.form-group {
  padding-bottom: 10px;

  .form-control {
    .size(100%, 40px);

    display: block;
    border: 1px solid #ccc;
    padding-left: 10px;

    &:focus {
      border-color: @color-yellow;
      outline: none;
    }
  }

  label {
    .font-size(13);

    display: block;
    color: @black;
  }



  &.has-error {
    .form-control {
      border-color: @color-link;
    }
  }
}

.button-line {
  padding-top: 75px;
}
.button-moderate-line {
  padding-top: 25px;
  text-align: center;
}

//todo why btn-orange so hardcoded? move to btn.less maybe and add class btn-big for big sizes
.btn-orange {
  .size(310px,70px);
  .font-size(13);

  color: @white;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 70px;
  text-align: center;
  border: none;
  background: @color-link;
  font-family: @font-base;
  max-width: 100%;
  display: inline-block;

  &:hover {
    cursor: pointer;
    color: @white;
    background: @color-link-hover;
  }

  &:focus {
    color: @white;
    outline: none;
  }

  &:visited {
    color: @white;
  }

  &.small-button {
    height: 40px;
    line-height: 40px;
  }
  &:disabled {
    opacity: 0.8;

    &:hover {
      cursor: default;
      color: @white;
      background: @color-link;
    }
  }

  &[data-progress] {
    font-size: 12px;
  }

  &.order-btn {
    width: 100%;
    height: 45px;
    line-height: 45px;
  }

  @media @mobile {
    width: 100%;
  }
}



.origin-check {
  padding-left: 24px;
  position: relative;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;

    &:not(:checked),
    &:not(.checked) {
      + span {
        &:before {
          border: 1px solid #6f7478;
        }
      }
    }

    &:checked, &.checked {
      + span {
        &:before {
          border: 2px solid @white;
        }

        &:after {
          position: absolute;
          left: 1px;
          top: -2px;
          font-family: @font-icon;
          font-size: 14px;
          line-height: 14px;
          content: '\e82d';
          color: @color-link-hover;
          z-index: 1;
        }
      }
    }
  }

  span {
    &:before {
      .size(16px, 16px);

      position: absolute;
      content: "";
      left: 0;
      top: 0;
    }
  }
}

.small-form {
  margin: 0 auto;
  width: 500px;

  @media @mobile {
    width: 100%;
  }
}

/*
<!-- Сделать оформление!!!!!!-->
*/
.messages-placeholder {
  display: none;

  &.visible {
    display: block;

    .alert {
      padding: 5px;
      margin: 5px auto;


      span {
        display: block;
        line-height: 24px;
      }

      &.alert-success {
        background-color: #dff0d8;
        border-color: #d6e9c6;
        color: #3c763d;
        line-height: 21px;

        .close{
          padding: 0;
          cursor: pointer;
          background: transparent;
          border: 0;
          -webkit-appearance: none;
          float: right;
          font-size: 21px;
          font-weight: bold;
          line-height: 1;
          color: #000000;
          text-shadow: 0 1px 0 #ffffff;
          opacity: 0.2;
          filter: alpha(opacity=20);
        }
      }

      &.alert-danger {
        background-color: #F3D8CF;
        border-color: #e07251;
        color: #e07251;
        line-height: 21px;

        .close{
          padding: 0;
          cursor: pointer;
          background: transparent;
          border: 0;
          -webkit-appearance: none;
          float: right;
          font-size: 21px;
          font-weight: bold;
          line-height: 1;
          color: #000000;
          text-shadow: 0 1px 0 #ffffff;
          opacity: 0.2;
          filter: alpha(opacity=20);
        }
      }
    }
  }
}

select {
  color: #fff;
  border: 1px solid #fff;
}

.alert {
  &.alert-danger {
    color: @color-link;
  }
}

.touch {
  #header {
    margin-bottom: -1px;

    a:hover::after {
      display:none;
    }
  }

  .material-block .select-material {
   border: 1px solid #fff;
  }

  .tooltip-wrapper {
    display: none!important;
  }

  .search-list .image .image-properties {
    border: 1px solid transparent;
    margin: -1px;
    width: ~"calc(100% + 2px)";
  }

  .events-header {
    background: #fff;
  }

}
.events-list {
  .clearfix();

  >li >a {
    height: 340px;
    max-width: 200px;

    &>.thumb-img {
      .size(200px, 267px);

      display: block;
      margin: 0 auto;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }

  >li {
    .size(200px,340px);

    float: left;
    width: 200px;
    margin-right: 8px;
    text-align: center;
    background: @white;
    position: relative;
    margin-bottom: 50px;

    &.people{
      height: 25px;
      width: 100%;
    }

    &:nth-child(6n) {
      margin-right: 0;
    }

    >a {
      .size(100%, 360px);

      display: block;
      position: absolute;
      top: 0;
      left: 0;
      color: @color-dark;
      text-align: center;

      .event-date {
        .size(100%, 28px);
        .font-size(11);

        position: absolute;
        top: 28px;
        opacity: 0;
        display: inline-block;
        left: 0;
        background: @color-link;
        color: @white;
        text-transform: uppercase;
        font-weight: bold;
        line-height: 28px;
        margin-top: -28px;
        transition: opacity 0.2s ease;
      }

      .event-numbers {
        .font-size(10);

        opacity: 0;
        text-transform: uppercase;
        font-family: @font-bebas;
        display: block;
        z-index: 12;
        position: relative;
        transition-property: opacity;
        transition-delay: 200ms;
        padding-top: 7px;

        .count {
          .font-size(20);

          margin-right: 4px;
          font-family: @font-serif;
        }

        .people-count {
          &:first-child {
            margin-right: 20px;
          }
        }
      }

      .dots {
        &:after {
          content: "...";
          position: absolute;
          top: 54px;
          right: 0;
          padding-right: 3px;
          z-index: 13;
          background: white;
          -webkit-box-shadow: -6px 0px 6px 0px rgba(255, 255, 255, 1);
          -moz-box-shadow: -6px 0px 6px 0px rgba(255, 255, 255, 1);
          box-shadow: -6px 0px 6px 0px rgba(255, 255, 255, 1);
          transition: all .2s ease-out 0s;
        }
      }

      .event-name {
        display: inline-block;
        width: 100%;
        color:  @color-dark;
        transition: all 0.2s ease;
        top: 0;
        position: relative;
        -webkit-perspective: 1000;
        -webkit-backface-visibility: hidden;

        .show {
          &:after {
            content: "...";
          }
        }

        .full {
          position: relative;
          background-color: rgba(255, 255, 255, 0.9);
          z-index: 12;
          display: block;
          padding: 0 3px;
          height: 72px;
          overflow: hidden;
          text-overflow: ellipsis;
          background: @white;

          @media @mobile {
            width: 100%;
            margin: 0 auto;
          }
        }
      }

      &:before {
        .size(100%, 100%);

        background: @white;
        box-shadow: 1px 0 15px rgba(0,0,0,.4);
        position: absolute;
        left: -20px;
        top: -20px;
        opacity: 0;
        content: "";
        z-index: -1;
        transition: opacity .1s ease-out 0s;
        box-sizing: content-box;
        padding: 20px;
      }

      &:hover{
        z-index: 20;

        .event-numbers {
          opacity: 1;
        }

        .dots:after {
          right: -20px;
        }

        &::before {
          transition: opacity .2s ease-out 0s;
          opacity: 1;
        }

        .event-date {
          opacity: 1;
          top: 0;
          -webkit-transition: top 0.2s;
          transition: top 0.2s;
        }
      }

      &.private {
        .thumb-img {
          &:before {
            display: none;
            content: 'Private';
            font-family: @font-bebas;
            width: 100%;
            height: 20px;
            font-size: 16px;
            top: 50%;
            text-align: center;
            bottom: 0;
            left: 0;
            position: absolute;
            z-index: 2;
          }

          &:after {
            display: none;
            content: '\e82e';
            font-family: drewaltizer;
            color: #3C3C3C;
            text-align: center;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            position: absolute;
            font-size: 55px;
            line-height: 220px;
            background-repeat: no-repeat;
            background-position: 50% 25%;
            background-color: rgba(255,255,255, 0.7);
          }
        }

        &:hover {
          .thumb-img {
            &:before,
            &:after {
              display: block;
            }
          }
        }
      }
    }
  }

  .private-img {
    &:hover {
      &:before{
        content: 'Private';
        font-family: @font-bebas;
        width: 100%;
        height: 20px;
        font-size: 16px;
        top: 70%;
        text-align: center;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 2;
        color: #3C3C3C;
      }

      &:after{
        content: '\e82e';
        font-family: drewaltizer;
        color: #3C3C3C;
        text-align: center;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        font-size: 55px;
        line-height: 220px;
        background-repeat: no-repeat;
        background-position: 50% 25%;
        background-color: rgba(255,255,255, 0.7);
      }
    }
  }

  &.homepage-list {
    margin-bottom: 35px;

    >li {
      .size(150px, 280px);
      width: 150px;
      margin-bottom: 40px;
      margin-right: 20px;
      position: relative;

      &:nth-child(6n) {
        margin-right: 0;
      }

      >a {
        .event-numbers {
          .people-count {
            margin-right: 5px;
          }
        }
      }
    }
  }

  @media @mobile {
    width: 100%;
    margin: auto;
    padding: 0 10px;

    > li > a {
      .size(100%, 468px);

      max-width: none;
      left: 0;
      margin-left: 0;

      &:hover {
        .event-name {
          &.lines-3,
          &.lines-4,
          &.lines-5,
          &.lines-6,
          &.lines-7,
          &.lines-8,
          &.lines-9 {
            -webkit-transform: none;
            transform: none;
          }

          &.lines-1 .event-numbers {
            padding-top: 15px;
          }
        }
      }

      .thumb-img {
        width: 100%;
        height: 400px;
      }

      .event-name {
        &.lines-1 {
          top: -18px;
        }
        &.lines-2 {
          top: -36px;
        }
        &.lines-3 {
          top: -54px;
        }
        &.lines-4 {
          top: -72px;
        }
        &.lines-5 {
          top: -90px;
        }
        &.lines-6 {
          top: -108px;
        }
        &.lines-7 {
          top: -126px;
        }
        &.lines-8 {
          top: -142px;
        }
        &.lines-9 {
          top: -160px;
        }

        .full {
          background: rgba(255, 255, 255, 0.90);
        }

        .event-date,
        .event-numbers {
          opacity: 1;
          top: 0;
        }
      }

      &.private {
        .thumb-img {
          &:before,
          &:after {
            display: block;
          }
        }
      }
    }

    li{
      .size(100%, 468px);
      margin-right: 0;
      margin-bottom: 0;
      overflow: hidden;

      .event-name {
        width: 180px;
        margin: 0 auto;
        display: block;

        &.dots:after {
          content: "";
        }
      }
    }

    &.homepage-list {
      li {
        .event-name {
          width: 100%;
        }

        a .event-date,
        a .event-numbers{
          opacity: 1;
        }

        a .event-name,
        a:hover .event-name {
          -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
          top: 35px;
        }

        a:before {
          box-shadow: none;
        }
      }
    }

    &.all-events-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      li:not(.number-list-item) {
        .size(260px, 468px);
        overflow: hidden;
        margin: 0 20px;
      }
    }
  }
}

.clone-image {
  z-index: 500000;
}

//
// Mixins
// --------------------------------------------------

// Clearfix
// --------
// For clearing floats like a boss h5bp.com/q
.clearfix() {
  *zoom: 1;

  &:before,
  &:after {
    display: table;
    content: "";
    // Fixes Opera/contenteditable bug:
    // http://nicolasgallagher.com/micro-clearfix-hack/#comment-36952
    line-height: 0;
  }

  &:after {
    clear: both;
  }
}

.clearfix, .cf {
  .clearfix();
}

// Sizing shortcuts
// -------------------------
.size(@width, @height) {
  width: @width;
  height: @height;
}

.square(@size) {
  .size(@size, @size);
}

// Placeholder text
// -------------------------
.placeholder(@color: @placeholderText) {
  &:-moz-placeholder {
    color: @color;
  }
  &:-ms-input-placeholder {
    color: @color;
  }
  &::-webkit-input-placeholder {
    color: @color;
  }
}


//mixin for font-size

.font-size(@sizeValue) {
  @remValue: (@sizeValue/16);
  @pxValue: (@sizeValue);
  font-size: ~"@{pxValue}px";
  font-size: ~"@{remValue}rem";
}


//mixin for line-height

.line-height(@heightValue){
  @remValue: (@heightValue/16);
  @pxValue: (@heightValue);
  line-height: ~"@{pxValue}px";
  line-height: ~"@{remValue}rem";
}

//mixin for triangle
.triangle-base() {
  content: '';
  display: block;
  width: 0;
  height: 0;
}

.triangle(@direction, @size, @color) {
  .triangle(@direction, @size * 2, @size, @color);
}

.triangle(@direction, @width, @height, @color) when (@direction = up) {
  .triangle-base();
  border-left: (@width / 2)solid transparent;
  border-right: (@width / 2)solid transparent;
  border-bottom: @height solid @color;
}

.triangle(@direction, @width, @height, @color) when (@direction = down) {
  .triangle-base();
  border-left: (@width / 2) solid transparent;
  border-right: (@width / 2) solid transparent;
  border-top: @height solid @color;
}

.triangle(@direction, @width, @height, @color) when (@direction = left) {
  .triangle-base();
  border-top: (@width / 2) solid transparent;
  border-bottom: (@width / 2) solid transparent;
  border-right: @height solid @color;
}

.triangle(@direction, @width, @height, @color) when (@direction = right) {
  .triangle-base();
  border-top: (@width / 2) solid transparent;
  border-bottom: (@width / 2) solid transparent;
  border-left: @height solid @color;
}

// Buttons
.make-color-btn(@color, @color-dark, @color-text, @active-shadow: .5) {
  color: rgba(red(@color-text), green(@color-text), blue(@color-text), .9);
  background: @color;

  &:hover {
    color: rgba(red(@color-text), green(@color-text), blue(@color-text), .6);
    background: @color-dark;
  }

  &:active {
    box-shadow: 0 1px 4px rgba(0, 0, 0, @active-shadow) inset;
  }

  &.btn-icon {
    color: @color;
    background: transparent;

    &:hover,&.hover {
      color: rgba(red(@color-text), green(@color-text), blue(@color-text), .6);
      background: @color-dark;
    }

    &:active {
      box-shadow: 0 1px 4px rgba(0, 0, 0, @active-shadow) inset;
    }
  }
}

.flexbox() {
  display: -webkit-flex;
  display: flex;
}

.flex-inline() {
  display: -webkit-inline-flex;
  display: inline-flex;
}

.flex {
  .flexbox();
}

.flex-half {
  flex: 0 0 50%;
}
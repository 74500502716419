input,
button,
select {
  -webkit-appearance: none;
  border-radius: 0;
}
.locked {
  position: relative;
}
.form-line {
  *zoom: 1;
}
.form-line:before,
.form-line:after {
  display: table;
  content: "";
  line-height: 0;
}
.form-line:after {
  clear: both;
}
.form-line:before,
.form-line:after {
  display: table;
  content: "";
  line-height: 0;
}
.form-line:after {
  clear: both;
}
.form-line .wide-input {
  width: 373px;
}
.form-line .narrow-input {
  width: 204px;
}
.form-line .half-input,
.form-line .half-input-desktop-only {
  width: 49%;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .form-line .wide-input {
    width: 100%;
  }
  .form-line .narrow-input {
    width: 100%;
  }
  .form-line .half-input-desktop-only {
    width: 100%;
  }
}
.form-group {
  padding-bottom: 10px;
}
.form-group .form-control {
  width: 100%;
  height: 40px;
  display: block;
  border: 1px solid #ccc;
  padding-left: 10px;
}
.form-group .form-control:focus {
  border-color: #f3b202;
  outline: none;
}
.form-group label {
  font-size: 13px;
  font-size: 0.8125rem;
  display: block;
  color: #000000;
}
.form-group.has-error .form-control {
  border-color: #e07251;
}
.button-line {
  padding-top: 75px;
}
.button-moderate-line {
  padding-top: 25px;
  text-align: center;
}
.btn-orange {
  width: 310px;
  height: 70px;
  font-size: 13px;
  font-size: 0.8125rem;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 70px;
  text-align: center;
  border: none;
  background: #e07251;
  font-family: Helvetica Neue, helvetica, sans-serif;
  max-width: 100%;
  display: inline-block;
}
.btn-orange:hover {
  cursor: pointer;
  color: #ffffff;
  background: #e38063;
}
.btn-orange:focus {
  color: #ffffff;
  outline: none;
}
.btn-orange:visited {
  color: #ffffff;
}
.btn-orange.small-button {
  height: 40px;
  line-height: 40px;
}
.btn-orange:disabled {
  opacity: 0.8;
}
.btn-orange:disabled:hover {
  cursor: default;
  color: #ffffff;
  background: #e07251;
}
.btn-orange[data-progress] {
  font-size: 12px;
}
.btn-orange.order-btn {
  width: 100%;
  height: 45px;
  line-height: 45px;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .btn-orange {
    width: 100%;
  }
}
.origin-check {
  padding-left: 24px;
  position: relative;
  cursor: pointer;
}
.origin-check input {
  position: absolute;
  opacity: 0;
}
.origin-check input:not(:checked) + span:before,
.origin-check input:not(.checked) + span:before {
  border: 1px solid #6f7478;
}
.origin-check input:checked + span:before,
.origin-check input.checked + span:before {
  border: 2px solid #ffffff;
}
.origin-check input:checked + span:after,
.origin-check input.checked + span:after {
  position: absolute;
  left: 1px;
  top: -2px;
  font-family: 'drewaltizer', sans-serif;
  font-size: 14px;
  line-height: 14px;
  content: '\e82d';
  color: #e38063;
  z-index: 1;
}
.origin-check span:before {
  width: 16px;
  height: 16px;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
}
.small-form {
  margin: 0 auto;
  width: 500px;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .small-form {
    width: 100%;
  }
}
/*
<!-- Сделать оформление!!!!!!-->
*/
.messages-placeholder {
  display: none;
}
.messages-placeholder.visible {
  display: block;
}
.messages-placeholder.visible .alert {
  padding: 5px;
  margin: 5px auto;
}
.messages-placeholder.visible .alert span {
  display: block;
  line-height: 24px;
}
.messages-placeholder.visible .alert.alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
  line-height: 21px;
}
.messages-placeholder.visible .alert.alert-success .close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
.messages-placeholder.visible .alert.alert-danger {
  background-color: #F3D8CF;
  border-color: #e07251;
  color: #e07251;
  line-height: 21px;
}
.messages-placeholder.visible .alert.alert-danger .close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity=20);
}
select {
  color: #fff;
  border: 1px solid #fff;
}
.alert.alert-danger {
  color: #e07251;
}
.table-scrollable {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.table {
  width: 100%;
}
.table th {
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: bold;
  background: #efefef;
  border-bottom: 1px solid #e5e5e5;
  padding: 5px 0 5px 5px;
  text-align: left;
}
.table th:first-child {
  padding-left: 10px;
}
.table td {
  font-size: 12px;
  font-size: 0.75rem;
  border-bottom: 1px solid #e5e5e5;
  padding: 10px 5px;
}
.table td.item-image {
  width: 140px;
}
.table td.item-image img {
  width: 120px;
}
.table td:first-child {
  padding-left: 10px;
}
.table-bordered {
  border: 1px solid #e5e5e5;
}
.table-bordered td {
  border-right: 1px solid #e5e5e5;
}
.pagination {
  clear: both;
  padding: 20px 0;
  text-align: right;
}
.pagination li {
  display: inline-block;
}
.pagination li a {
  font-size: 12px;
  font-size: 0.75rem;
  margin: 0 5px;
  color: #e07251;
}
.pagination li.active a {
  points-event: none;
  color: #1f262d;
}
.btn {
  display: inline-block;
  vertical-align: top;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.65em;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  outline: none;
  margin: 0;
  border: none;
  border-radius: 2px;
  box-shadow: none;
  cursor: pointer;
  padding: 0.55em 2.5em;
}
.btn:hover {
  color: rgba(255, 255, 255, 0.5);
  background: #1f262d;
}
.btn.btn-facebook {
  width: 100%;
  font-weight: 700;
  text-align: center;
  border: none;
  margin-bottom: 20px;
  height: 40px;
  font-size: 0.8125rem;
  text-transform: uppercase;
  color: #ffffff;
  background: #3f6b9d;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .btn.btn-facebook {
    font-size: 0.7125rem;
  }
}
.btn.btn-facebook.account-settings {
  width: 300px;
}
.btn.btn-facebook:hover {
  background: #295587;
}
.btn.btn-facebook i {
  margin-right: 5px;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .btn.btn-facebook {
    padding: 7px 30px;
  }
  .btn.btn-facebook.account-settings {
    width: 100%;
  }
}
.btn-block {
  width: 100%;
}
.btn-min {
  display: inline-block;
  line-height: 1em;
  padding: 2px 5px;
  vertical-align: baseline;
  width: 25px;
}
.btn-min > input {
  display: none;
}
.btn-navigation {
  margin: 7px 5px 7px 0;
  padding: 0.2em 0.5em;
}
.btn-green {
  color: rgba(255, 255, 255, 0.9);
  background: #76a262;
}
.btn-green:hover {
  color: rgba(255, 255, 255, 0.6);
  background: #638a52;
}
.btn-green:active {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
}
.btn-green.btn-icon {
  color: #76a262;
  background: transparent;
}
.btn-green.btn-icon:hover,
.btn-green.btn-icon.hover {
  color: rgba(255, 255, 255, 0.6);
  background: #638a52;
}
.btn-green.btn-icon:active {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
}
.btn-red {
  color: rgba(255, 255, 255, 0.9);
  background: #e55d71;
}
.btn-red:hover {
  color: rgba(255, 255, 255, 0.6);
  background: #df3a52;
}
.btn-red:active {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
}
.btn-red.btn-icon {
  color: #e55d71;
  background: transparent;
}
.btn-red.btn-icon:hover,
.btn-red.btn-icon.hover {
  color: rgba(255, 255, 255, 0.6);
  background: #df3a52;
}
.btn-red.btn-icon:active {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
}
.btn-blue {
  color: rgba(255, 255, 255, 0.9);
  background: #46a9d3;
}
.btn-blue:hover {
  color: rgba(255, 255, 255, 0.6);
  background: #2e96c2;
}
.btn-blue:active {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
}
.btn-blue.btn-icon {
  color: #46a9d3;
  background: transparent;
}
.btn-blue.btn-icon:hover,
.btn-blue.btn-icon.hover {
  color: rgba(255, 255, 255, 0.6);
  background: #2e96c2;
}
.btn-blue.btn-icon:active {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
}
.btn-white {
  color: rgba(0, 0, 0, 0.9);
  background: #ffffff;
}
.btn-white:hover {
  color: rgba(0, 0, 0, 0.6);
  background: #ebebeb;
}
.btn-white:active {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
}
.btn-white.btn-icon {
  color: #ffffff;
  background: transparent;
}
.btn-white.btn-icon:hover,
.btn-white.btn-icon.hover {
  color: rgba(0, 0, 0, 0.6);
  background: #ebebeb;
}
.btn-white.btn-icon:active {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
}
.btn-white.btn-bordered {
  border: 1px solid #a9a9a9;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  vertical-align: top;
  margin-top: 8px;
  padding: 0 10px;
  font-family: Arial;
}
.btn-gray {
  color: rgba(255, 255, 255, 0.9);
  background: #4d4d4d;
}
.btn-gray:hover {
  color: rgba(255, 255, 255, 0.6);
  background: #393939;
}
.btn-gray:active {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
}
.btn-gray.btn-icon {
  color: #4d4d4d;
  background: transparent;
}
.btn-gray.btn-icon:hover,
.btn-gray.btn-icon.hover {
  color: rgba(255, 255, 255, 0.6);
  background: #393939;
}
.btn-gray.btn-icon:active {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
}
.btn-lgray {
  color: rgba(255, 255, 255, 0.9);
  background: #5E5E5E;
}
.btn-lgray:hover {
  color: rgba(255, 255, 255, 0.6);
  background: #6c6a6a;
}
.btn-lgray:active {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
}
.btn-lgray.btn-icon {
  color: #5E5E5E;
  background: transparent;
}
.btn-lgray.btn-icon:hover,
.btn-lgray.btn-icon.hover {
  color: rgba(255, 255, 255, 0.6);
  background: #6c6a6a;
}
.btn-lgray.btn-icon:active {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
}
.btn-light-gray {
  color: rgba(0, 0, 0, 0.9);
  background: #D3D3D3;
}
.btn-light-gray:hover {
  color: rgba(0, 0, 0, 0.6);
  background: #9f9f9f;
}
.btn-light-gray:active {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
}
.btn-light-gray.btn-icon {
  color: #D3D3D3;
  background: transparent;
}
.btn-light-gray.btn-icon:hover,
.btn-light-gray.btn-icon.hover {
  color: rgba(0, 0, 0, 0.6);
  background: #9f9f9f;
}
.btn-light-gray.btn-icon:active {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.5) inset;
}
.btn-light-gray.min.btn-min {
  font-weight: bold;
  width: 70px;
  padding: 5px 0;
}
.spiral-lock {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.6);
}
.spiral-lock.spiral-lock-default #circularG {
  width: 128px;
  height: 128px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.spiral-lock.spiral-lock-default .circularG {
  position: absolute;
  background-color: #2E9EE8;
  width: 29px;
  height: 29px;
  -moz-border-radius: 19px;
  -moz-animation-name: bounce_circularG;
  -moz-animation-duration: 1.04s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-direction: linear;
  -webkit-border-radius: 19px;
  -webkit-animation-name: bounce_circularG;
  -webkit-animation-duration: 1.04s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-direction: linear;
  -ms-border-radius: 19px;
  -ms-animation-name: bounce_circularG;
  -ms-animation-duration: 1.04s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-direction: linear;
  -o-border-radius: 19px;
  -o-animation-name: bounce_circularG;
  -o-animation-duration: 1.04s;
  -o-animation-iteration-count: infinite;
  -o-animation-direction: linear;
  border-radius: 19px;
  animation-name: bounce_circularG;
  animation-duration: 1.04s;
  animation-iteration-count: infinite;
  animation-direction: linear;
}
.spiral-lock.spiral-lock-default #circularG_1 {
  left: 0;
  top: 50px;
  -moz-animation-delay: 0.39s;
  -webkit-animation-delay: 0.39s;
  -ms-animation-delay: 0.39s;
  -o-animation-delay: 0.39s;
  animation-delay: 0.39s;
}
.spiral-lock.spiral-lock-default #circularG_2 {
  left: 14px;
  top: 14px;
  -moz-animation-delay: 0.52s;
  -webkit-animation-delay: 0.52s;
  -ms-animation-delay: 0.52s;
  -o-animation-delay: 0.52s;
  animation-delay: 0.52s;
}
.spiral-lock.spiral-lock-default #circularG_3 {
  top: 0;
  left: 50px;
  -moz-animation-delay: 0.65s;
  -webkit-animation-delay: 0.65s;
  -ms-animation-delay: 0.65s;
  -o-animation-delay: 0.65s;
  animation-delay: 0.65s;
}
.spiral-lock.spiral-lock-default #circularG_4 {
  right: 14px;
  top: 14px;
  -moz-animation-delay: 0.78s;
  -webkit-animation-delay: 0.78s;
  -ms-animation-delay: 0.78s;
  -o-animation-delay: 0.78s;
  animation-delay: 0.78s;
}
.spiral-lock.spiral-lock-default #circularG_5 {
  right: 0;
  top: 50px;
  -moz-animation-delay: 0.91s;
  -webkit-animation-delay: 0.91s;
  -ms-animation-delay: 0.91s;
  -o-animation-delay: 0.91s;
  animation-delay: 0.91s;
}
.spiral-lock.spiral-lock-default #circularG_6 {
  right: 14px;
  bottom: 14px;
  -moz-animation-delay: 1.04s;
  -webkit-animation-delay: 1.04s;
  -ms-animation-delay: 1.04s;
  -o-animation-delay: 1.04s;
  animation-delay: 1.04s;
}
.spiral-lock.spiral-lock-default #circularG_7 {
  left: 50px;
  bottom: 0;
  -moz-animation-delay: 1.17s;
  -webkit-animation-delay: 1.17s;
  -ms-animation-delay: 1.17s;
  -o-animation-delay: 1.17s;
  animation-delay: 1.17s;
}
.spiral-lock.spiral-lock-default #circularG_8 {
  left: 14px;
  bottom: 14px;
  -moz-animation-delay: 1.3s;
  -webkit-animation-delay: 1.3s;
  -ms-animation-delay: 1.3s;
  -o-animation-delay: 1.3s;
  animation-delay: 1.3s;
}
@-moz-keyframes bounce_circularG {
  0% {
    -moz-transform: scale(1);
  }
  100% {
    -moz-transform: scale(0.3);
  }
}
@-webkit-keyframes bounce_circularG {
  0% {
    -webkit-transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.3);
  }
}
@-ms-keyframes bounce_circularG {
  0% {
    -ms-transform: scale(1);
  }
  100% {
    -ms-transform: scale(0.3);
  }
}
@-o-keyframes bounce_circularG {
  0% {
    -o-transform: scale(1);
  }
  100% {
    -o-transform: scale(0.3);
  }
}
@keyframes bounce_circularG {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.3);
  }
}
.spiral-lock.spiral-lock-progress .progress {
  margin: auto 25%;
  min-height: 25px;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
.spiral-lock.spiral-lock-moment {
  background-color: rgba(0, 0, 0, 0.6);
}
.spiral-lock.spiral-lock-moment .spiral-lock-moment-inner {
  position: absolute;
  margin-top: -28px;
  text-align: center;
  height: 80px;
  top: 50%;
  width: 100%;
}
.spiral-lock.spiral-lock-moment .spiral-lock-moment-inner svg {
  width: 80px;
  height: 80px;
}
.spiral-lock.spiral-lock-moment .spiral-lock-moment-inner svg circle {
  fill: none;
  stroke-width: 8;
  stroke: #01c2c9;
  stroke-dasharray: 0px 200px;
  stroke-linecap: round;
  -moz-animation: moment 2s linear infinite;
  -webkit-animation: moment 2s linear infinite;
  animation: moment 2s linear infinite;
  -webkit-transform-origin: 40px 40px 0;
  -moz-transform-origin: 40px 40px 0;
  transform-origin: 40px 40px 0;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  transform: rotate(-90deg);
}
.spiral-lock.spiral-lock-moment .spiral-lock-moment-inner:after {
  content: "Loading";
  color: #ffffff;
  line-height: 80px;
  position: absolute;
  font-weight: bold;
  left: 50%;
  margin-left: -25px;
}
@-webkit-keyframes moment {
  50% {
    stroke-dasharray: 200px 200px;
    -webkit-transform: rotate(-90deg);
  }
  100% {
    stroke-dasharray: 0px 200px;
    -webkit-transform: rotate(270deg);
  }
}
@-moz-keyframes moment {
  50% {
    stroke-dasharray: 200px 200px;
    -moz-transform: rotate(-90deg);
  }
  100% {
    stroke-dasharray: 0px 200px;
    -moz-transform: rotate(270deg);
  }
}
@keyframes moment {
  50% {
    stroke-dasharray: 200px 200px;
    transform: rotate(-90deg);
  }
  100% {
    stroke-dasharray: 0px 200px;
    transform: rotate(270deg);
  }
}
.spiral-lock.spiral-lock-da {
  background-image: url("../../../media/images/logo-small.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.spiner {
  margin: 60px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid #e07251;
  border-right: 1.1em solid #e07251;
  border-bottom: 1.1em solid #e07251;
  border-left: 1.1em solid #ffffff;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
.spiner,
.spiner:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.progress-bar {
  position: relative;
  width: 50%;
  margin: 60px auto;
  height: 5em;
  border: 1px solid #e07251;
}
.progress-bar .progress {
  position: absolute;
  left: 0;
  height: 100%;
  background: #e07251;
  text-align: center;
}
.progress-bar .progress .percents {
  color: #ffffff;
  line-height: 5em;
}
.modal {
  position: fixed;
  outline: none;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0);
  transform: translateX(-50%) translateY(-50%) translateZ(0);
  -webkit-filter: blur(0);
  background: #ffffff;
  padding: 50px;
  width: 410px;
  display: none;
  z-index: 10002;
  border: 1px solid transparent;
  margin: -1px;
  box-shadow: 0 0 0 2px #e5e5e5;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .modal {
    overflow: auto;
    max-height: 100vh;
  }
}
.modal.facebook-share {
  width: 600px;
  height: 450px;
}
.modal.facebook-share h3 {
  font-family: 'Helvetica';
}
.modal.facebook-share .button-line {
  text-align: center;
}
.modal.facebook-share .facebook-message {
  width: 100%;
  height: 55%;
  resize: none;
  border: none;
  font-family: 'Helvetica';
  margin-top: 20px;
  cursor: default;
  overflow-y: hidden;
}
.modal.facebook-share .custom-message {
  font-family: 'Helvetica';
  resize: none;
  width: 100%;
  margin-top: 20px;
  height: 230px;
  padding: 10px;
}
.modal.cropper-preview-popup {
  max-width: 60vw;
  min-width: 640px;
}
.modal.preview-attachment {
  width: auto;
  min-width: 400px;
}
.modal.preview-attachment h2 {
  margin-bottom: 20px;
  margin-top: -15px;
}
.modal.preview-attachment .preview-attachment-container {
  text-align: center;
  font-weight: bold;
}
.modal.preview-attachment .preview-attachment-container .filename {
  max-width: 400px;
  overflow: hidden;
  word-break: break-word;
}
.modal.preview-attachment .preview-attachment-container img {
  max-width: 70vw;
  max-height: 70vh;
  width: auto;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .modal.preview-attachment {
    width: 100%;
    margin-left: -20px;
    max-width: 410px;
    min-width: auto;
  }
}
.modal.show {
  display: block;
}
.modal p {
  margin-bottom: 25px;
}
.modal .spaced-bottom {
  margin-bottom: 10px;
}
.modal .modal-form {
  width: 310px;
}
.modal .close-link {
  position: absolute;
  right: 15px;
  top: 15px;
}
.modal h2 {
  font-size: 20px;
  font-size: 1.25rem;
  font-family: Oswald, 'bebas-neue', 'Bebas', sans-serif;
  margin-bottom: 30px;
}
.modal .password-restore {
  padding-bottom: 10px;
  color: #e07251;
  display: none;
}
.modal .password-restore.show {
  display: block;
}
.modal .password-restore a {
  font-weight: bold;
}
.modal .button-line {
  padding-top: 30px;
}
.modal .subscribe {
  text-align: center;
  text-transform: uppercase;
  font-family: Helvetica Neue, helvetica, sans-serif;
}
.modal .subscribe p {
  font-size: 12px;
  font-size: 0.75rem;
  margin-bottom: 5px;
}
.modal .subscribe p.price {
  margin-top: 25px;
}
.modal .subscribe .button-line {
  margin-bottom: 50px;
  padding-top: 10px;
}
.modal .subscribe .button-line .btn-orange {
  font-size: 20px;
  font-size: 1.25rem;
  width: 50%;
  color: #000000;
  margin: auto;
  font-family: Oswald, 'bebas-neue', 'Bebas', sans-serif;
  vertical-align: inherit;
  background-color: white;
  border: 3px solid #e07251;
  height: 60px;
  line-height: 35px;
  position: relative;
}
.modal .subscribe .button-line .btn-orange:after {
  width: 15px;
  height: 3px;
  background: #f3b202;
  display: block;
  position: absolute;
  left: 50%;
  bottom: 12px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  content: "";
  transition: width 0.3s ease-in;
}
.modal .subscribe .button-line .btn-orange:hover:after {
  width: 113px;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .modal .subscribe .button-line .btn-orange {
    width: 90%;
  }
  .modal .subscribe .button-line .btn-orange:after {
    width: 113px;
  }
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .modal {
    width: 100%;
    max-width: 410px;
    overflow-y: scroll;
    max-height: 100%;
  }
  .modal .modal-form {
    width: 100%;
  }
}
.modal .copy-to-clipboard {
  text-align: center;
}
.modal .copy-to-clipboard button {
  width: 200px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
.modal-show header,
.modal-show main,
.modal-show footer,
.modal-show .top-slider {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  points-event: none;
}
#fade {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.3);
  left: 0;
  top: 0;
  z-index: 3000;
}
.modal.r-modal {
  font-weight: bold;
  text-align: center;
  color: #656565;
}
.modal.r-modal.modal-success {
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-filter: none;
}
.modal.r-modal.modal-success .message:before {
  font-family: drewaltizer;
  content: "\e82d";
  font-size: 19px;
  margin-right: 7px;
  vertical-align: middle;
  margin-top: 3px;
}
.modal.r-modal.modal-danger {
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-filter: none;
}
.modal.r-modal.modal-danger .message:before {
  font-family: drewaltizer;
  content: "\e80e";
  font-size: 19px;
  margin-right: 7px;
  vertical-align: middle;
  margin-top: 3px;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  body.with-modal.ios-11 {
    height: 0;
    overflow: hidden;
  }
  body.with-modal.ios-11 footer,
  body.with-modal.ios-11 > div:not(.modal),
  body.with-modal.ios-11 > div:not(.modal) * {
    display: none !important;
  }
  body.with-modal.ios-11 .modal {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: none;
    bottom: 0;
    right: 0;
  }
}
.events-list {
  *zoom: 1;
}
.events-list:before,
.events-list:after {
  display: table;
  content: "";
  line-height: 0;
}
.events-list:after {
  clear: both;
}
.events-list:before,
.events-list:after {
  display: table;
  content: "";
  line-height: 0;
}
.events-list:after {
  clear: both;
}
.events-list > li > a {
  height: 340px;
  max-width: 200px;
}
.events-list > li > a > .thumb-img {
  width: 200px;
  height: 267px;
  display: block;
  margin: 0 auto;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.events-list > li {
  height: 340px;
  float: left;
  width: 200px;
  margin-right: 8px;
  text-align: center;
  background: #ffffff;
  position: relative;
  margin-bottom: 50px;
}
.events-list > li.people {
  height: 25px;
  width: 100%;
}
.events-list > li:nth-child(6n) {
  margin-right: 0;
}
.events-list > li > a {
  width: 100%;
  height: 360px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  color: #1f262d;
  text-align: center;
}
.events-list > li > a .event-date {
  width: 100%;
  height: 28px;
  font-size: 11px;
  font-size: 0.6875rem;
  position: absolute;
  top: 28px;
  opacity: 0;
  display: inline-block;
  left: 0;
  background: #e07251;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: bold;
  line-height: 28px;
  margin-top: -28px;
  transition: opacity 0.2s ease;
}
.events-list > li > a .event-numbers {
  font-size: 10px;
  font-size: 0.625rem;
  opacity: 0;
  text-transform: uppercase;
  font-family: Oswald, 'bebas-neue', 'Bebas', sans-serif;
  display: block;
  z-index: 12;
  position: relative;
  transition-property: opacity;
  transition-delay: 200ms;
  padding-top: 7px;
}
.events-list > li > a .event-numbers .count {
  font-size: 20px;
  font-size: 1.25rem;
  margin-right: 4px;
  font-family: 'Garamond', 'Times New Roman', serif;
}
.events-list > li > a .event-numbers .people-count:first-child {
  margin-right: 20px;
}
.events-list > li > a .dots:after {
  content: "...";
  position: absolute;
  top: 54px;
  right: 0;
  padding-right: 3px;
  z-index: 13;
  background: white;
  -webkit-box-shadow: -6px 0px 6px 0px #ffffff;
  -moz-box-shadow: -6px 0px 6px 0px #ffffff;
  box-shadow: -6px 0px 6px 0px #ffffff;
  transition: all 0.2s ease-out 0s;
}
.events-list > li > a .event-name {
  display: inline-block;
  width: 100%;
  color: #1f262d;
  transition: all 0.2s ease;
  top: 0;
  position: relative;
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}
.events-list > li > a .event-name .show:after {
  content: "...";
}
.events-list > li > a .event-name .full {
  position: relative;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 12;
  display: block;
  padding: 0 3px;
  height: 72px;
  overflow: hidden;
  text-overflow: ellipsis;
  background: #ffffff;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .events-list > li > a .event-name .full {
    width: 100%;
    margin: 0 auto;
  }
}
.events-list > li > a:before {
  width: 100%;
  height: 100%;
  background: #ffffff;
  box-shadow: 1px 0 15px rgba(0, 0, 0, 0.4);
  position: absolute;
  left: -20px;
  top: -20px;
  opacity: 0;
  content: "";
  z-index: -1;
  transition: opacity 0.1s ease-out 0s;
  box-sizing: content-box;
  padding: 20px;
}
.events-list > li > a:hover {
  z-index: 20;
}
.events-list > li > a:hover .event-numbers {
  opacity: 1;
}
.events-list > li > a:hover .dots:after {
  right: -20px;
}
.events-list > li > a:hover::before {
  transition: opacity 0.2s ease-out 0s;
  opacity: 1;
}
.events-list > li > a:hover .event-date {
  opacity: 1;
  top: 0;
  -webkit-transition: top 0.2s;
  transition: top 0.2s;
}
.events-list > li > a.private .thumb-img:before {
  display: none;
  content: 'Private';
  font-family: Oswald, 'bebas-neue', 'Bebas', sans-serif;
  width: 100%;
  height: 20px;
  font-size: 16px;
  top: 50%;
  text-align: center;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 2;
}
.events-list > li > a.private .thumb-img:after {
  display: none;
  content: '\e82e';
  font-family: drewaltizer;
  color: #3C3C3C;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  font-size: 55px;
  line-height: 220px;
  background-repeat: no-repeat;
  background-position: 50% 25%;
  background-color: rgba(255, 255, 255, 0.7);
}
.events-list > li > a.private:hover .thumb-img:before,
.events-list > li > a.private:hover .thumb-img:after {
  display: block;
}
.events-list .private-img:hover:before {
  content: 'Private';
  font-family: Oswald, 'bebas-neue', 'Bebas', sans-serif;
  width: 100%;
  height: 20px;
  font-size: 16px;
  top: 70%;
  text-align: center;
  bottom: 0;
  left: 0;
  position: absolute;
  z-index: 2;
  color: #3C3C3C;
}
.events-list .private-img:hover:after {
  content: '\e82e';
  font-family: drewaltizer;
  color: #3C3C3C;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  font-size: 55px;
  line-height: 220px;
  background-repeat: no-repeat;
  background-position: 50% 25%;
  background-color: rgba(255, 255, 255, 0.7);
}
.events-list.homepage-list {
  margin-bottom: 35px;
}
.events-list.homepage-list > li {
  height: 280px;
  width: 150px;
  margin-bottom: 40px;
  margin-right: 20px;
  position: relative;
}
.events-list.homepage-list > li:nth-child(6n) {
  margin-right: 0;
}
.events-list.homepage-list > li > a .event-numbers .people-count {
  margin-right: 5px;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .events-list {
    width: 100%;
    margin: auto;
    padding: 0 10px;
  }
  .events-list > li > a {
    width: 100%;
    height: 468px;
    max-width: none;
    left: 0;
    margin-left: 0;
  }
  .events-list > li > a:hover .event-name.lines-3,
  .events-list > li > a:hover .event-name.lines-4,
  .events-list > li > a:hover .event-name.lines-5,
  .events-list > li > a:hover .event-name.lines-6,
  .events-list > li > a:hover .event-name.lines-7,
  .events-list > li > a:hover .event-name.lines-8,
  .events-list > li > a:hover .event-name.lines-9 {
    -webkit-transform: none;
    transform: none;
  }
  .events-list > li > a:hover .event-name.lines-1 .event-numbers {
    padding-top: 15px;
  }
  .events-list > li > a .thumb-img {
    width: 100%;
    height: 400px;
  }
  .events-list > li > a .event-name.lines-1 {
    top: -18px;
  }
  .events-list > li > a .event-name.lines-2 {
    top: -36px;
  }
  .events-list > li > a .event-name.lines-3 {
    top: -54px;
  }
  .events-list > li > a .event-name.lines-4 {
    top: -72px;
  }
  .events-list > li > a .event-name.lines-5 {
    top: -90px;
  }
  .events-list > li > a .event-name.lines-6 {
    top: -108px;
  }
  .events-list > li > a .event-name.lines-7 {
    top: -126px;
  }
  .events-list > li > a .event-name.lines-8 {
    top: -142px;
  }
  .events-list > li > a .event-name.lines-9 {
    top: -160px;
  }
  .events-list > li > a .event-name .full {
    background: rgba(255, 255, 255, 0.9);
  }
  .events-list > li > a .event-name .event-date,
  .events-list > li > a .event-name .event-numbers {
    opacity: 1;
    top: 0;
  }
  .events-list > li > a.private .thumb-img:before,
  .events-list > li > a.private .thumb-img:after {
    display: block;
  }
  .events-list li {
    width: 100%;
    height: 468px;
    margin-right: 0;
    margin-bottom: 0;
    overflow: hidden;
  }
  .events-list li .event-name {
    width: 180px;
    margin: 0 auto;
    display: block;
  }
  .events-list li .event-name.dots:after {
    content: "";
  }
  .events-list.homepage-list li .event-name {
    width: 100%;
  }
  .events-list.homepage-list li a .event-date,
  .events-list.homepage-list li a .event-numbers {
    opacity: 1;
  }
  .events-list.homepage-list li a .event-name,
  .events-list.homepage-list li a:hover .event-name {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
    top: 35px;
  }
  .events-list.homepage-list li a:before {
    box-shadow: none;
  }
  .events-list.all-events-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .events-list.all-events-list li:not(.number-list-item) {
    width: 260px;
    height: 468px;
    overflow: hidden;
    margin: 0 20px;
  }
}
.clone-image {
  z-index: 500000;
}
.touch #header {
  margin-bottom: -1px;
}
.touch #header a:hover::after {
  display: none;
}
.touch .material-block .select-material {
  border: 1px solid #fff;
}
.touch .tooltip-wrapper {
  display: none!important;
}
.touch .search-list .image .image-properties {
  border: 1px solid transparent;
  margin: -1px;
  width: calc(100% + 2px);
}
.touch .events-header {
  background: #fff;
}
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memQYaGs126MiZpBA-UFUIcVXSCEkx2cmqvXlWq8tWZ0Pw86hd0Rk8ZkWVAexQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVc.ttf) format('truetype');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsg-1x4gaVc.ttf) format('truetype');
}
.profile-content {
  font-family: Arial, sans-serif;
}
.profile-content a {
  color: #f3b202;
}
.profile-content .title-block {
  font-size: 14px;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #000;
  padding-bottom: 50px;
}
.profile-content .title-block a {
  float: right;
}
.profile-content .welcome-block {
  text-align: center;
  color: #000;
  padding-bottom: 75px;
}
.profile-content .welcome-block .welcome-text {
  font-size: 30px;
  font-size: 1.875rem;
  line-height: 30px;
  line-height: 1.875rem;
  font-family: 'Open Sans', sans-serif;
  color: #999999;
  padding-bottom: 5px;
  display: block;
}
.profile-content .welcome-block .welcome-text .name {
  color: #000;
}
.profile-content .welcome-block .icon {
  display: block;
  text-align: center;
  font-size: 15px;
  line-height: 15px;
  margin: 20px 0 10px;
}
.profile-content .welcome-block .subscribe-text {
  font-size: 13px;
  font-size: 0.8125rem;
  color: #999999;
}
.profile-content .welcome-block .subscribe-text .check-label {
  position: relative;
  display: inline-block;
  padding-left: 25px;
  cursor: pointer;
}
.profile-content .welcome-block .subscribe-text .check-label input[type=checkbox] {
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.profile-content .welcome-block .subscribe-text .check-label:before {
  width: 18px;
  height: 18px;
  position: absolute;
  border: 1px solid #cccccc;
  content: "";
  top: 0;
  left: 0;
}
.profile-content .welcome-block .subscribe-text .check-label input:checked + span:before {
  content: "\e806";
  font-family: "drewaltizer";
  color: #f3b202;
  position: absolute;
  top: 1px;
  left: 2px;
  font-size: 10px;
}
.profile-content .profile-block {
  border-top: 1px solid #d1d1d1;
  padding: 30px 0 110px;
}
.profile-content table {
  width: 100%;
}
.profile-content table thead {
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 12px;
  line-height: 0.75rem;
  color: #999999;
  text-align: left;
}
.profile-content table thead td,
.profile-content table thead th {
  padding-bottom: 10px;
  text-align: left;
}
.profile-content table tbody td {
  font-size: 13px;
  font-size: 0.8125rem;
  line-height: 20px;
  line-height: 1.25rem;
  border-top: 1px solid #d1d1d1;
  padding: 10px 50px 10px 0;
  min-height: 60px;
  height: 60px;
  color: #000;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif;
  vertical-align: middle;
}
.profile-content table tbody td:last-child {
  padding-right: 0;
}
.profile-content table tbody td .product-description {
  height: 60px;
  max-width: 400px;
  padding-right: 70px;
  position: relative;
}
.profile-content table tbody td .product-description .image-wrapper {
  width: 60px;
  height: 60px;
  overflow: hidden;
  position: relative;
  float: left;
}
.profile-content table tbody td .product-description .image-wrapper img {
  min-height: 150%;
  max-height: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
}
.profile-content table tbody td .product-description .image-wrapper.plan-img img {
  min-height: 0;
  max-width: 100%;
  bottom: 50%;
  transform: translateY(50%);
}
.profile-content table tbody td .product-description .text {
  display: block;
  font-weight: normal;
  margin-left: 80px;
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.profile-content table tbody td .product-description .photo-count {
  width: 70px;
  height: 20px;
  font-size: 12px;
  top: 34px;
  left: -5px;
  text-align: center;
  z-index: 5;
  font-style: italic;
  color: #fff;
  position: absolute;
  background: #f3b202;
}
.profile-content table tbody td .product-description.with-count:before {
  content: "";
  border: 5px solid transparent;
  border-right-color: #d27f09;
  left: -10px;
  top: 49px;
  z-index: -1;
  position: absolute;
}
.profile-content table tbody td .plan-duration {
  font-weight: bold;
  font-size: 11px;
  padding: 0 5px;
  color: #fff;
  display: inline-block;
}
.profile-content table tbody td .active {
  color: #50ab00;
}
.profile-content table tbody td .active .plan-duration {
  background: #5a82a1;
}
.profile-content table tbody td .no-active {
  color: #999999;
}
.profile-content table tbody td .no-active .plan-duration {
  background: #c2c2c2;
}
.profile-content .no-subscribe-block {
  font-size: 14px;
  font-size: 0.875rem;
  line-height: 24px;
  line-height: 1.5rem;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  color: #999999;
}
.profile-content .no-subscribe-block .name-line {
  font-size: 40px;
  font-size: 2.5rem;
  line-height: 40px;
  line-height: 2.5rem;
  display: block;
  color: #f3b202;
}
.profile-content .no-subscribe-block .info-line {
  font-size: 20px;
  font-size: 1.25rem;
  color: #000;
  margin-bottom: 25px;
  display: block;
}
.profile-content .price-list {
  padding-top: 50px;
}
.profile-content .price-list a {
  color: #e2724c;
}
.profile-content .large-link {
  font-size: 12px;
  font-size: 0.75rem;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 50px;
  font-weight: bold;
}
.popup-link {
  color: #000;
}
.modal-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  left: 0;
  top: 0;
  z-index: 1000;
}
@media screen and (max-width: 801px), screen and (max-height: 801px) and (max-width: 1024px) {
  .profile-content table {
    text-align: center;
  }
  .profile-content table thead th {
    padding: 0 5px 10px;
  }
  .profile-content table tbody td {
    height: auto;
    padding-right: 10px;
  }
  .profile-content table tbody td .product-description {
    height: auto;
    padding-right: 20px;
  }
  .profile-content table tbody td .product-description .text {
    top: auto;
    transform: none;
  }
  .profile-content .welcome-block {
    padding-bottom: 30px;
  }
  .profile-content .welcome-block .subscribe-text {
    margin-top: 30px;
  }
  .profile-content .welcome-block .subscribe-text .check-label {
    padding: 10px 0 10px 25px;
  }
  .profile-content .welcome-block .subscribe-text .check-label:before {
    top: 10px;
  }
  .profile-content .welcome-block .subscribe-text .check-label input:checked + span:before {
    top: 11px;
  }
  .profile-content .profile-block.no-orders {
    padding-bottom: 0;
  }
}

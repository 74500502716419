@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,400italic);

@import (reference) "variables";
@import (reference) "mixins";

.profile-content {
  font-family: Arial, sans-serif;

  a {
    color: #f3b202;
  }

  .title-block {
    .font-size(14);

    text-transform: uppercase;
    font-weight: bold;
    color: #000;
    padding-bottom: 50px;

    a {
      float: right;
    }
  }

  .welcome-block {
    text-align: center;
    color: #000;
    padding-bottom: 75px;

    .welcome-text {
      .font-size(30);
      .line-height(30);

      font-family: 'Open Sans', sans-serif;
      color: #999999;
      padding-bottom: 5px;
      display: block;

      .name {
        color: #000;
      }
    }

    .icon {
      display: block;
      text-align: center;
      font-size: 15px;
      line-height: 15px;
      margin: 20px 0 10px;
    }

    .subscribe-text {
      .font-size(13);

      color: #999999;


      .check-label {
        position: relative;
        display: inline-block;
        padding-left: 25px;
        cursor: pointer;

        input[type=checkbox] {
          position: absolute;
          opacity: 0;
          z-index: -1;
        }

        &:before {
          .size(18px, 18px);

          position: absolute;
          border: 1px solid #cccccc;
          content: "";
          top: 0;
          left: 0;
        }

        input:checked + span:before {
          content: "\e806";
          font-family: "drewaltizer";
          color: #f3b202;
          position: absolute;
          top: 1px;
          left: 2px;
          font-size: 10px;
        }
      }
    }
  }

  .profile-block {
    border-top: 1px solid #d1d1d1;
    padding: 30px 0 110px;
  }

  table {
    width: 100%;
    thead {
      .font-size(12);
      .line-height(12);

      color: #999999;
      text-align: left;

      td, th {
        padding-bottom: 10px;
        text-align: left;
      }
    }

    tbody {
      td {
        .font-size(13);
        .line-height(20);

        border-top: 1px solid #d1d1d1;
        padding: 10px 50px 10px 0;
        min-height: 60px;
        height: 60px;
        color: #000;
        font-weight: bold;
        font-family: 'Open Sans', sans-serif;
        vertical-align: middle;

        &:last-child {
          padding-right: 0;
        }

        .product-description {
          height: 60px;
          max-width: 400px;
          padding-right: 70px;
          position: relative;

          .image-wrapper {
            width: 60px;
            height: 60px;
            overflow: hidden;
            position: relative;
            float: left;

            img {
              min-height: 150%;
              max-height: 100%;
              position: absolute;
              right: 0;
              bottom: 0;
            }

            &.plan-img img{
              min-height: 0;
              max-width: 100%;
              bottom: 50%;
              transform: translateY(50%);

            }
          }

          .text {
            display: block;
            font-weight: normal;
            margin-left: 80px;
            position: relative;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
          }

          .photo-count {
            .size(70px, 20px);

            font-size: 12px;
            top: 34px;
            left: -5px;
            text-align: center;
            z-index: 5;
            font-style: italic;
            color: #fff;
            position: absolute;
            background: #f3b202;
          }

          &.with-count:before {
            content: "";
            border: 5px solid transparent;
            border-right-color: #d27f09;
            left: -10px;
            top: 49px;
            z-index: -1;
            position: absolute;
          }
        }

        .plan-duration {
          font-weight: bold;
          font-size: 11px;
          padding: 0 5px;
          color: #fff;
          display: inline-block;
        }

        .active {
          color: #50ab00;

          .plan-duration {
            background: #5a82a1;
          }
        }

        .no-active{
          color: #999999;

          .plan-duration {
            background: #c2c2c2;
          }
        }
      }
    }
  }

  .no-subscribe-block {
    .font-size(14);
    .line-height(24);

    text-align: center;
    font-family: "Open Sans",sans-serif;
    color: #999999;

    .name-line {
      .font-size(40);
      .line-height(40);

      display: block;
      color: #f3b202;
    }

    .info-line {
      .font-size(20);

      color: #000;
      margin-bottom: 25px;
      display: block;
    }
  }

  .price-list {
    padding-top: 50px;
    a {
      color: #e2724c;
    }
  }

  .large-link {
    .font-size(12);

    text-transform: uppercase;
    display: inline-block;
    margin-top: 50px;
    font-weight: bold;
  }
}

.popup-link {
  color: #000;
}

.modal-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .2);
  left: 0;
  top: 0;
  z-index: 1000;
}

@media @mobile {
  .profile-content {
    table {
      text-align: center;

      thead {
        th {
          padding: 0 5px 10px;
        }
      }
      tbody {
        td {
          height: auto;
          padding-right: 10px;

          .product-description {
            height: auto;
            padding-right: 20px;

            .text {
              top: auto;
              transform: none;
            }
          }
        }
      }
    }

    .welcome-block {
      padding-bottom: 30px;

      .subscribe-text {
        margin-top: 30px;

        .check-label {
          padding: 10px 0 10px 25px;

          &:before {
            top: 10px;
          }

          input:checked + span:before {
            top: 11px;
          }
        }
      }
    }

    .profile-block {
      &.no-orders {
        padding-bottom: 0;
      }
    }
  }
}
@import (reference) "variables";
@import (reference) "mixins";

.table-scrollable {
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch
}

.table {
  width: 100%;

  th {
    .font-size(10);

    font-weight: bold;
    background: #efefef;
    border-bottom: 1px solid @color-title-border;
    padding: 5px 0 5px 5px;
    text-align: left;

    &:first-child {
      padding-left: 10px;
    }
  }

  td {
    .font-size(12);

    border-bottom: 1px solid @color-title-border;
    padding: 10px 5px;
    &.item-image {
      width: 140px;
      img {
        width: 120px;
      }
    }
    &:first-child {
      padding-left: 10px;
    }
  }
}

.table-bordered {
  border: 1px solid @color-title-border;

  td {
    border-right: 1px solid @color-title-border;
  }
}
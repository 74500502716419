@import (reference) "variables";
@import (reference) "mixins";

.btn {
  display: inline-block;
  vertical-align: top;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.65em;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  outline: none;
  margin: 0;
  border: none;
  border-radius: 2px;
  box-shadow: none;
  cursor: pointer;
  padding: .55em 2.5em;

  &:hover {
    color: rgba(255, 255, 255, .5);
    background: @color-dark;
  }
  &.btn-facebook{
    width: 100%;
    font-weight: 700;
    text-align: center;
    border: none;
    margin-bottom: 20px;
    height: 40px;
    font-size: .8125rem;
    text-transform: uppercase;
    color: #ffffff;
    background: #3f6b9d;
    @media @mobile{
      font-size: .7125rem;
    }

    &.account-settings{
      width: 300px;
    }
    &:hover{
      background: #295587;
    }

    i{
      margin-right: 5px;
    }

    @media @mobile {
      padding: 7px 30px;

      &.account-settings{
        width: 100%;
      }
    }
  }
}

.btn-block {
  width: 100%;
}

.btn-min {
  display: inline-block;
  line-height: 1em;
  padding: 2px 5px;
  vertical-align: baseline;
  width: 25px;

  > input {
    display: none;
  }
}

.btn-navigation {
  margin: 7px 5px 7px 0;
  padding: .2em .5em;
}

.btn-green {
  .make-color-btn(@color-btn-green, darken(@color-btn-green, 8%), #fff);
}

.btn-red {
  .make-color-btn(@color-btn-red, darken(@color-btn-red, 8%), #fff);
}

.btn-blue {
  .make-color-btn(@color-btn-blue, darken(@color-btn-blue, 8%), #fff);
}

.btn-white {
  .make-color-btn(@white, darken(@white, 8%), #000);
  &.btn-bordered {
    border: 1px solid #a9a9a9;
    height: 24px;
    line-height: 24px;
    display: inline-block;
    vertical-align: top;
    margin-top: 8px;
    padding: 0 10px;
    font-family: Arial;
}
}

.btn-gray {
  .make-color-btn(@gray, darken(@gray, 8%), #FFF);
}

.btn-lgray {
  .make-color-btn(#5E5E5E, darken(#696767, -1%), #FFF);
}

.btn-light-gray {
  .make-color-btn(#D3D3D3, darken(#A2A2A2, 1%), #000);
  &.min.btn-min {
    font-weight: bold;
    width: 70px;
    padding: 5px 0;
  }
}

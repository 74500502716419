@import (reference) "variables";
@import (reference) "mixins";

.modal {
  position: fixed;
  outline: none;
  top: 50%;
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%) translateZ(0);
  transform: translateX(-50%) translateY(-50%) translateZ(0);
  -webkit-filter: blur(0);
  background: @white;
  padding: 50px;
  width: 410px;
  display: none;
  z-index: 10002;
  border: 1px solid transparent;
  margin: -1px;
  box-shadow: 0 0 0 2px @color-title-border;
  @media @mobile {
    overflow: auto;
    max-height: 100vh;
  }
  &.facebook-share {
    width: 600px;
    height: 450px;
    h3 {
      font-family: @font-helvetica;
    }
    .button-line {
      text-align: center;
    }
    .facebook-message {
      width: 100%;
      height: 55%;
      resize: none;
      border: none;
      font-family: @font-helvetica;
      margin-top: 20px;
      cursor: default;
      overflow-y: hidden;
    }
    .custom-message {
      font-family: @font-helvetica;
      resize: none;
      width: 100%;
      margin-top: 20px;
      height: 230px;
      padding: 10px;
    }
  }
  &.cropper-preview-popup {
    max-width: 60vw;
    min-width: 640px;
  }
  &.preview-attachment{
    width: auto;
    min-width: 400px;
    h2{
      margin-bottom: 20px;
      margin-top: -15px;
    }
    .preview-attachment-container{
      text-align: center;
      font-weight: bold;
      .filename{
        max-width: 400px;
        overflow: hidden;
        word-break: break-word;
      }
      img{
        max-width:70vw;
        max-height: 70vh;
        width: auto;
      }
    }

    @media @mobile {
      width: 100%;
      margin-left: -20px;
      max-width: 410px;
      min-width: auto;
    }
  }

  &.show{
    display: block;
  }

  p {
    margin-bottom: 25px;
  }
  .spaced-bottom{
    margin-bottom: 10px;
  }

  .modal-form {
    width: 310px;
  }

  .close-link {
    position: absolute;
    right: 15px;
    top: 15px;
  }

  h2 {
    .font-size(20);

    font-family: @font-bebas;
    margin-bottom: 30px;
  }

  .password-restore {
    padding-bottom: 10px;
    color: @color-link;
    display: none;

    &.show{
      display: block;
    }

    a {
      font-weight: bold;
    }
  }

  .button-line {
    padding-top: 30px;
  }

  .subscribe{
    text-align: center;
    text-transform: uppercase;
    font-family: @font-base;

    p {
      .font-size(12);

      margin-bottom: 5px;

      &.price {
        margin-top: 25px;
      }
    }

    .button-line {
      margin-bottom: 50px;
      padding-top: 10px;

      .btn-orange {
        .font-size(20);

        width: 50%;
        color: @black;
        margin: auto;
        font-family: @font-bebas;
        vertical-align: inherit;
        background-color: white;
        border: 3px solid @color-link;
        height: 60px;
        line-height: 35px;
        position: relative;

        &:after {
          width: 15px;
          height: 3px;
          background: #f3b202;
          display: block;
          position: absolute;
          left: 50%;
          bottom: 12px;
          -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
          content: "";
          transition: width 0.3s ease-in;
        }

        &:hover {
          &:after {
            width: 113px;
          }
        }
      }
    }

    @media @mobile {
      .button-line {
        .btn-orange {
          width: 90%;

          &:after {
            width: 113px;
          }
        }
      }
    }
  }

  @media @mobile {
    width: 100%;
    max-width: 410px;
    overflow-y: scroll;
    max-height: 100%;

    .modal-form {
      width: 100%;
    }
  }
  .copy-to-clipboard {
    text-align: center;
    button {
      width: 200px;
      height: 40px;
      line-height: 40px;
      cursor: pointer;
    }
  }
}

.modal-show {
  header,
  main,
  footer,
  .top-slider {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
    points-event: none;
  }
}

#fade {
  .size(100%,100%);

  position: fixed;
  background: rgba(0,0,0,.3);
  left: 0;
  top: 0;
  z-index: 3000;
}

.modal {
  &.r-modal{
    font-weight:bold;
    text-align: center;
    color: #656565;

    &.modal-success{
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      -webkit-filter: none;
      
      .message{
        &:before{
          font-family: drewaltizer;
          content:"\e82d";
          font-size: 19px;
          margin-right: 7px;
          vertical-align: middle;
          margin-top: 3px;
        }
      }
    }

    &.modal-danger{
      -webkit-transform: translateX(-50%) translateY(-50%);
      transform: translateX(-50%) translateY(-50%);
      -webkit-filter: none;

      .message{
        &:before{
          font-family: drewaltizer;
          content: "\e80e";
          font-size: 19px;
          margin-right: 7px;
          vertical-align: middle;
          margin-top: 3px;
        }
      }
    }
  }
}

@media @mobile {
  body.with-modal.ios-11 {
    height: 0;
    overflow: hidden;

    footer,
    > div:not(.modal),
    > div:not(.modal) * {
      display: none !important;
    }

    .modal {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      transform: none;
      bottom: 0;
      right: 0;
    }
  }
}